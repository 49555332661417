@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/avocadoVariables";

.advanceTable {
  display: flex;
  flex-direction: column;
  gap: map-get($spacing, sm);
}

.cardState {
  margin: map-get($spacing, lg) 0 0;
}
