@import "../../../../../../assets/scss/mixin";
@import "../../../../../../assets/scss/avocadoVariables";

.addGroupSection {
  padding: map-get($spacing, md);

  &_title {
    margin: 0 0 map-get($spacing, xl);
  }

  &_content {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, sm);

    .textField {
      width: 318px;
    }
  }
}
