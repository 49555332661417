@import "../../../../../assets/scss/avocadoVariables";

.resultsEditHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: map-get($spacing, sm);
  padding: map-get($spacing, xs) map-get($spacing, xxxl);
  box-shadow: var(--shadow-elevate);
  background: linear-gradient(0deg, var(--color-neutral-30-20) 0%, var(--color-neutral-30-20) 100%),
    var(--color-neutral-00);

  &_actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: map-get($spacing, sm);
  }
}
