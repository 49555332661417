@import "../../../../../assets/scss/avocadoVariables";

.sessionsAccordion {
  &_content {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, xs);

    .addBtn {
      margin: map-get($spacing, xxs) 0 0;
    }
  }

  div[class*="_accordion_head"] {
    [class*="_heading"] {
      color: var(--color-neutral-85);
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
