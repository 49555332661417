@import "../../../assets/scss/mixin";
@import "../../../assets/scss/avocadoVariables";

.multiSelect {
  max-width: unset;

  [class*="_accordion_content"] {
    padding: map-get($spacing, lg);
  }

  &_termPartByCategory {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, xs);

    &:not(:last-of-type) {
      margin: 0 0 map-get($spacing, xl);
    }

    .termParts {
      display: flex;
      flex-wrap: wrap;
      gap: map-get($spacing, md);

      &_item {
        width: auto;

        .termPartLabel {
          margin: 0 0 0 map-get($spacing, xs);
        }
      }
    }
  }

  &_count {
    margin: map-get($spacing, xxl) 0 map-get($spacing, sm);
  }

  &_buttonsSection {
    display: flex;
    justify-content: flex-start;
    gap: map-get($spacing, sm);
  }
}
