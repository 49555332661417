@import "../../../../../../assets/scss/mixin";
@import "../../../../../../assets/scss/avocadoVariables";

.advanceSearch {
  padding: map-get($spacing, xxl) map-get($spacing, xxxl);

  &_header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: map-get($spacing, sm);

    .separatorTitle {
      height: 20px;
      width: 1px;
      background-color: var(--color-neutral-30);
    }

    .headerSubtitle {
      color: var(--color-neutral-70);
    }
  }

  .content {
    padding: map-get($spacing, xl) 0 0;
  }

  .footer {
    padding: map-get($spacing, xxl) 0 0;
    gap: map-get($spacing, sm);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
