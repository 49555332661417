@import "../../../../../../assets/scss/mixin";
@import "../../../../../../assets/scss/avocadoVariables";

.createLink {
  &_warnNotification {
    margin: map-get($spacing, xl) 0 0;
  }

  &_content {
    margin: map-get($spacing, lg) 0 0;
  }

  &_validations {
    padding: map-get($spacing, sm) 0 0;
  }
}
