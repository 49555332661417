@import "../../../../assets/scss/avocadoVariables";

.groupTableCourseColumn {
  display: flex;
  flex-direction: column;
  gap: map-get($spacing, xxs);

  &_code {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_pills {
    display: flex;
    flex-wrap: wrap;
    gap: map-get($spacing, xxs);

    .togglePills {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      cursor: pointer;
    }
  }
}
