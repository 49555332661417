@import "../../../../../assets/scss/avocadoVariables";

.instructorsTable {
  &_instructor {
    display: grid;
    grid-template-columns: 24px 1fr;
    gap: map-get($spacing, sm);
    align-items: center;
    justify-content: flex-start;
  }

  &_inputField {
    max-width: 50px;

    svg {
      flex-shrink: 0;
    }
  }
}
