@import "../../../assets/scss/mixin";
@import "../../../assets/scss/avocadoVariables";

.codeText {
  font-weight: 500;
}

.columnLink > [class*="_link_content"],
.columnCell {
  word-break: break-word;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.columnLink {
  color: var(--color-primary-50);
  display: block;
}

.columnPill {
  width: fit-content;
}

.columnOpenGroup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.columnLabels {
  display: flex;
  flex-wrap: wrap;
  gap: map-get($spacing, xxs);
}
