@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/avocadoVariables";

.search {
  display: flex;
  gap: map-get($spacing, sm);
  margin: map-get($spacing, xl) 0 0;

  &_contextSearch {
    width: 100%;
  }

  &_button {
    width: 210px;
  }
}
