@import "../../../assets/scss/mixin";
@import "../../../assets/scss/avocadoVariables";

.groupMigration {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: calc(100vh - 50px);
  display: block;
  background: var(--color-neutral-00);

  &_content {
    padding: map-get($spacing, xxl) map-get($spacing, xxxl);

    .title {
      color: var(--color-neutral-85);
      margin: 0 0 map-get($spacing, sm);
    }

    .linkSelector {
      width: 448px;

      [class*="reactSelect__value-container--has-value"] {
        [class*="linkSelector_linkOptionLabel_secondaryLabel"] {
          display: none;
        }
      }
    }

    .informationBox {
      background: var(--color-blocked-opacity);
      border-radius: map-get($radius, lg);
      margin: 0 0 map-get($spacing, lg);
      min-width: 448px;
      padding: map-get($spacing, xs);
      width: fit-content;

      &_title {
        color: var(--color-neutral-85);
        margin: 0 0 map-get($spacing, xs);
      }

      &_subtitle {
        color: var(--color-neutral-85);
        font-weight: map-get($fonts, bold);
        margin: map-get($spacing, sm) 0 map-get($spacing, xs);
      }

      &_content {
        display: flex;
        gap: map-get($spacing, xxs);

        .divider {
          height: 16px;
          svg > path {
            fill: var(--color-neutral-30);
          }
        }

        .text {
          color: var(--color-neutral-85);
        }

        .bold {
          color: var(--color-neutral-70);
        }
      }
    }

    .customInformationBox {
      @extend .informationBox;
      margin: map-get($spacing, sm) 0 0;
    }

    .contentActions {
      display: flex;
      gap: map-get($spacing, sm);
      margin: map-get($spacing, xxl) 0 0;
    }

    .notificationWarn {
      width: 448px;
      margin: map-get($spacing, sm) 0 0;

      &_text {
        margin: 0 0 map-get($spacing, xxs);
      }

      &_list {
        display: flex;
        flex-direction: column;
        gap: map-get($spacing, xxs);
        list-style: none;
        padding: 0 0 0 map-get($spacing, sm);

        li {
          position: relative;
          padding: 0 0 0 map-get($spacing, sm);

          &::before {
            background-color: var(--color-neutral-90);
            border-radius: 50%;
            content: "";
            height: 4px;
            left: 0;
            position: absolute;
            top: map-get($spacing, xs);
            transform: translateY(-50%);
            width: 4px;
          }
        }
      }

      &_link {
        padding: 0 0 0 map-get($spacing, xl);
      }
    }
  }
}
