@import "../../../../../assets/scss/avocadoVariables";

.weeksForkCard {
  border-radius: map-get($radius, lg);
  border: 1px solid var(--color-neutral-10);
  background: var(--color-blocked-opacity);
  padding: map-get($spacing, md);
  margin: map-get($spacing, xl) 0 0;

  &_title {
    margin: 0 0 map-get($spacing, sm);
  }

  &_description {
    margin: 0 0 map-get($spacing, md);
  }
}
