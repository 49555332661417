@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/avocadoVariables";

.incidenceTable {
  display: flex;
  flex-direction: column;
  gap: map-get($spacing, sm);
  margin: map-get($spacing, xl) 0 0;

  .numberWeek {
    font-weight: map-get($fonts, medium);

    &_date {
      font-weight: 400;
    }
  }

  .incidenceValidation {
    &:not(:first-child) {
      color: var(--color-neutral-70);
      font-size: map-get($sizeText, 12);
      line-height: map-get($sizeText, 16);
    }
  }

  .weekHoliday {
    &_title {
      font-weight: map-get($fonts, medium);
    }
  }
}

.notificationCard {
  &:not(:first-child) {
    margin: map-get($spacing, md) 0 0;
  }
}
