@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/avocadoVariables";

.resultEdit {
  .content {
    position: relative;
    overflow-x: hidden;
    max-width: 100vw;
    display: grid;
    grid-template-columns: minmax(190px, 230px) 1fr auto;
    gap: map-get($spacing, md);
    padding: map-get($spacing, xxl) map-get($spacing, xxxl);

    @for $i from 100 through 200 {
      &.content__height#{$i} {
        $height: $i * 1px;
        min-height: calc(100vh - #{$height} - 50px);
      }
    }

    .aside {
      width: 100%;
    }
  }
}
