@import "../../../../assets/scss/avocadoVariables";
@import "../../../../assets/scss/mixin";

.sharedBlocks {
  display: flex;
  flex-direction: column;

  &_title {
    margin: 0 0 map-get($spacing, md);
  }

  &_grid {
    display: grid;
    gap: map-get($spacing, xs);
    grid-template-columns: 92px 8px 92px;

    .separator {
      align-items: center;
      color: var(--color-neutral-90);
      display: flex;
      justify-content: center;
    }
  }

  .sharedBlocks_error {
    margin: map-get($spacing, sm) 0 0;
    color: var(--color-error-50);
  }
}
