@import "../../../assets/scss/avocadoVariables";
@import "../../../assets/scss/mixin";

.context {
  flex: 1;
  max-width: 412px;
  border-left: map-get($borders, md) solid var(--color-neutral-30);
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: map-get($spacing, xl);
  padding: 0 map-get($spacing, sm);
  align-items: center;
  margin: 0 0 0 map-get($spacing, sm);

  &:hover {
    background-color: map-get($colors, layout-grey-5);
    color: map-get($colors, layout-grey-90);
  }

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    &_label {
      font-size: map-get($sizeText, 10);
      line-height: map-get($sizeText, 12);
      color: #8c93a6;
      font-weight: 500;
      margin: 0 0 map-get($spacing, xxs);
    }

    &_info {
      font-size: map-get($sizeText, 12);
      line-height: map-get($sizeText, 16);
      color: #465669;
      font-weight: 500;
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.context_content {
  padding: map-get($spacing, md);
  min-height: 200px;
  .context_select {
    @include flex(space-between);
    min-height: 200px;
    padding: map-get($spacing, md) 0 map-get($spacing, md) 0.625rem;
    .input_select {
      @include text(map-get($sizeText, 12), layout-grey-50);
      width: 100%;
      min-width: 180px;
      height: 100%;
      padding: 0.625rem 0;
      margin: 0 0 0 0.625rem;
      label {
        margin: 0 0.625rem 0 0;
      }
      &:first-child {
        margin: 0;
      }
    }
  }

  .context_form {
    margin: 0.625rem 0 0;
    display: flex;
    justify-content: flex-end;

    &.context_form__btnTranslation {
      justify-content: space-between;
      align-items: center;
    }
  }
}
