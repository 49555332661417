@import "../../../../../../assets/scss/mixin";
@import "../../../../../../assets/scss/avocadoVariables";

.advanceSearch {
  padding: map-get($spacing, xxl) map-get($spacing, xxxl);

  &_header {
    margin: 0 0 map-get($spacing, lg);
  }

  &_content {
    padding: map-get($spacing, xxl) 0 0;

    .contentTitle {
      margin: 0 0 map-get($spacing, lg);
    }
  }

  .footer {
    padding: 2rem 0 0;
    &_btn {
      margin: 0 1rem 0 0;
      &:last-child {
        margin: 0;
      }
    }
  }
}
