@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/avocadoVariables";

.createSection {
  padding: map-get($spacing, xxl) map-get($spacing, xxxl);

  &_content {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, md);
    max-width: 1018px;
    width: 100%;
  }

  &_actionsButtons {
    align-items: center;
    display: flex;
    gap: map-get($spacing, sm);
    margin: map-get($spacing, xxl) 0 0;
  }
}
