@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/avocadoVariables";

.selectAllBtn {
  margin: 0 0 map-get($spacing, md) map-get($spacing, sm);
}

.accordion {
  .accordionItem {
    margin: 1.5rem 0 0;
    &:first-of-type {
      margin: 0;
    }
    .accordionButton {
      cursor: pointer;
    }
    .accordionHeader {
      display: flex;
      padding: 0.5rem 1.5rem 0.5rem 1rem;
      border-bottom: 1px solid map-get($colors, layout-grey-30);
      &_title {
        @include text(0.875rem, layout-grey-90);
        padding: 0 0 0 1.25rem;
        .bold {
          font-weight: map-get($fonts, bold);
        }
      }
      &_checkbox {
        flex: 1;
      }
      &_labelCheckbox {
        @include text(0.875rem, layout-grey-90, bold);
        align-items: flex-start;
        padding: 0.25rem 0 0 0.375rem;
        .regular {
          font-weight: map-get($fonts, regular);
        }
      }
      &_icon {
        @include flex(flex-end, center);
        width: 40px;
        height: 100%;
        color: map-get($colors, layout-grey-30);
        cursor: pointer;
        &.accordionHeader_icon__edit {
          background: map-get($colors, message-alert-50);
        }
      }
    }
    .accordionPanel {
      padding: 0.5rem 1.5rem 0.5rem 1rem;
      .item {
        display: flex;
        width: 100%;
        margin: 0.5rem 0 0;
        &:first-child {
          margin: 0;
        }
        &_icon {
          width: 14px;
          height: 14px;
          align-self: center;
          &.accordionPanel_icon__repeat {
            color: map-get($colors, message-alert-50);
          }
          &.accordionPanel_icon__check {
            color: map-get($colors, message-positive-50);
          }
          &.accordionPanel_icon__close {
            color: map-get($colors, message-negative-50);
          }
          &.accordionPanel_icon__plus {
            color: map-get($colors, darwined-blue-30);
          }
          &.accordionPanel_icon__trash {
            color: map-get($colors, layout-grey-40);
          }
        }
        &_box {
          flex: 1;
          margin: 0 0 0 0.375rem;
          &:active {
            &_deleted {
              background: layout-grey-5 !important;
            }
          }
          &_deleted {
            border: 1px solid map-get($colors, layout-grey-40);
          }
          &_deleted_background {
            background: map-get($colors, layout-grey-5);
          }
          .text {
            font-size: 0.625rem;
            &_bold {
              font-weight: map-get($fonts, bold);
            }
            &_deleted {
              color: map-get($colors, layout-grey-40);
            }
          }
        }
      }
      &_button {
        $leftMeasure: 1.25rem;
        width: calc(100% - #{$leftMeasure});
        margin: 0.5rem 0 0 $leftMeasure;
      }
    }
  }
}
