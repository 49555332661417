@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/avocadoVariables";

.weekTooltipLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: map-get($sizeText, 14);
  line-height: map-get($sizeText, 18);
  color: var(--color-neutral-90);
  gap: map-get($spacing, xxs);

  &_week {
    font-weight: map-get($fonts, medium);
    color: var(--color-primary-50);
  }
}
