@import "../../assets/scss/mixin";
@import "../../assets/scss/avocadoVariables";

.cntVacancies {
  position: relative;

  .contentBundle {
    padding: map-get($spacing, xxl) map-get($spacing, xxxl);

    .tabPanel {
      display: flex;
      flex-direction: column;
    }

    .tab {
      margin: 0 0 map-get($spacing, xl);
      display: flex;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -2px;
        left: 0;
        border-radius: 4px;
        background-color: var(--color-neutral-10);
      }

      &_item {
        position: relative;
        padding: map-get($spacing, xs) map-get($spacing, md);
        color: var(--color-neutral-50);
        font-size: map-get($sizeText, 16);
        line-height: map-get($sizeText, 20);
        font-weight: map-get($fonts, medium);
        background-color: transparent;
        display: inline;
        cursor: pointer;

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: -2px;
          left: 0;
          border-radius: 4px;
          background-color: transparent;
        }

        &[aria-selected="true"] {
          color: var(--color-neutral-90);

          &::before {
            background-color: var(--color-primary-50);
          }
        }
      }
    }
    .loader {
      min-height: calc(100vh - 50px);
    }

    .footer {
      padding: map-get($spacing, xxl) 0 map-get($spacing, lg) 0;
      display: flex;
      align-items: center;
      gap: map-get($spacing, sm);
    }
  }
}

.notificationCard {
  margin: map-get($spacing, xl) auto 0 0;
  width: auto;

  &_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: map-get($spacing, xxs);

    .actionButton {
      font-size: map-get($sizeText, 12);
      line-height: map-get($sizeText, 16);
      font-weight: map-get($fonts, medium);
      color: var(--color-neutral-70);
      padding: map-get($spacing, xxs) 0 0 0;
      background-color: transparent;
      cursor: pointer;
    }
  }
}
