@import "../../../../../assets/scss/avocadoVariables";

.sessionCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: map-get($spacing, xs);
  gap: map-get($spacing, xxs);
  border-radius: map-get($radius, lg);
  border: 1px solid transparent;
  background-color: var(--color-neutral-00);
  cursor: pointer;
  transition: all 0.25s ease-in-out;

  &:hover {
    background-color: var(--color-neutral-30-20);
    box-shadow: var(--shadow-md);
  }

  &_content {
    display: flex;
    flex-direction: column;

    .schedule,
    .weeks {
      color: var(--color-neutral-90);
    }

    .weeks,
    .key {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .key {
      color: var(--color-neutral-70);
    }
  }

  &_icon {
    flex-shrink: 0;
  }

  @for $colorId from 1 through 17 {
    &.sessionCard__#{$colorId} {
      border-color: var(--color-palette-#{$colorId}-50);

      &.sessionCard__selected,
      &:active,
      &:focus {
        background: color-mix(in srgb, var(--color-palette-#{$colorId}-50) 40%, transparent);
      }

      &.sessionCard__selected:hover {
        background-color: var(--color-palette-#{$colorId}-50-20);
      }
    }
  }

  &.sessionCard__deleted.sessionCard__deleted {
    background-color: var(--color-neutral-05);
    border-color: var(--color-neutral-10);

    .sessionCard_content {
      .schedule,
      .weeks,
      .key {
        color: var(--color-neutral-30);
      }
    }

    &:hover,
    &:active,
    &:focus {
      background-color: var(--color-neutral-30-20);
      border-color: var(--color-neutral-30);
    }

    &.sessionCard__selected {
      background-color: var(--color-gray-30-30);
      border-color: var(--color-neutral-50);

      &:hover {
        background-color: var(--color-neutral-05);
        border-color: var(--color-neutral-50);
      }
    }
  }
}
