@import "../../../assets/scss/mixin";
@import "../../../assets/scss/avocadoVariables";

.modalSectionEditor {
  top: 52px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: map-get($colors, layout-grey-10);
  z-index: 9999;
}

.gridInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--color-neutral-00);

  .infoWeeks {
    height: 100%;
    padding: 0;
  }
  .sectionGrid {
    width: 100%;
    padding: map-get($spacing, xl) map-get($spacing, xxxl) map-get($spacing, xl)
      map-get($spacing, md);
  }
}

.cntAccordion {
  margin: 1rem 0 0;
  width: 100%;

  &_item {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid map-get($colors, layout-grey-10);
    cursor: pointer;

    &:last-of-type {
      border-bottom: 0;
    }

    .itemText {
      margin: 0;
      text-transform: uppercase;
      padding: 0 map-get($spacing, xs) 0 0;
      overflow: hidden;
      text-overflow: ellipsis;

      &.itemText__large {
        -webkit-line-clamp: 3;
        line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      &:last-of-type {
        text-transform: initial;
        padding: 0;
      }

      &.itemText__error {
        color: var(--color-error-50);
      }
    }
  }
}

.modalHeader {
  span.modalHeader_bookingTitle {
    font-size: map-get($sizeText, 22);
    font-weight: map-get($fonts, medium);
    padding: 0;
  }
}
