@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/avocadoVariables";

.formHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: map-get($spacing, xs);
  min-width: 550px;
  margin: 0 0 map-get($spacing, md);

  &_details {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: map-get($spacing, xs);

    .divider {
      width: 1px;
      height: 18px;
      background-color: var(--color-neutral-30);
    }

    .detailsText {
      b {
        font-weight: map-get($fonts, medium);
      }
    }
  }

  .buttons {
    display: flex;
    &_item {
      margin: 0 0 0 1.5rem;
      &:first-of-type {
        margin: 0;
      }
    }
  }

  &_card {
    margin: 0 0 map-get($spacing, md);
  }
}
