@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/mixin";

.linkHistoryTable {
  width: 100%;
  max-width: 1120px;

  &_table {
    .tableLeftHeader {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &_total {
        color: map-get($colors, layout-grey-90);
        font-size: map-get($sizeText, 16);
        font-weight: map-get($fonts, medium);
      }

      &_separator {
        height: 18px;
        width: 1px;
        background-color: map-get($colors, layout-grey-10);
        margin: 0 map-get($spacing, xs) 0 map-get($spacing, md);
      }

      &_updatedAt {
        color: map-get($colors, layout-grey-70);
        font-size: map-get($sizeText, 16);

        .updatedAtLabel {
          font-weight: map-get($fonts, medium);
        }
      }
    }

    .tableMiddleAction {
      display: flex;
      align-items: center;
      gap: map-get($spacing, xxs);
    }

    .tableCol {
      color: map-get($colors, layout-grey-90);
      font-size: map-get($sizeText, 14);
      line-height: map-get($sizeText, 16);

      &__rightGap {
        padding: 0 map-get($spacing, lg) 0 0;
      }

      &__link {
        display: flex;
        color: map-get($colors, darwined-blue-50);
        font-weight: 400;
        line-break: anywhere;
      }

      &__listShort,
      &__list {
        display: flex;
        flex-direction: column;
      }

      &__list {
        gap: map-get($spacing, xs);
      }
    }

    & button[class*="pager-module"] {
      width: auto;
      min-width: 36px;
      padding: 0 map-get($spacing, xxs);
    }
  }
}
