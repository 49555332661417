@import "../../../../assets/scss/avocadoVariables";
@import "../../../../assets/scss/mixin";

.capacityTitle {
  margin: 0 0 map-get($spacing, md);
}

.sharedCapacity {
  width: 100%;
  display: flex;

  &_content {
    align-items: center;
    display: flex;
    gap: map-get($spacing, xs);

    .separator {
      align-items: center;
      color: var(--color-neutral-90);
      display: flex;
      justify-content: center;
    }

    .textField {
      overflow: hidden;
      width: 92px;
    }
  }

  &_checkbox {
    margin: 0 0 0 map-get($spacing, xl);
    align-items: center;
    align-content: center;
    display: flex;
  }

  .sharedCapacity_error {
    margin: map-get($spacing, sm) 0 0;
    color: var(--color-error-50);
  }
}
