@import "../../../../assets/scss/avocadoVariables";

.bookingModalDetails {
  &_content {
    padding: map-get($spacing, md);
    border-radius: map-get($radius, lg);
    background-color: var(--color-blocked-opacity);
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, sm);
    margin: 0 0 map-get($spacing, xxl);
  }

  &_footer {
    .footerAction {
      min-width: 120px;
      font-weight: map-get($fonts, medium);
    }
  }
}
