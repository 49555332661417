@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/avocadoVariables";

.days {
  &_label {
    margin: map-get($spacing, md) 0 map-get($spacing, xs);
  }

  &_options {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: map-get($spacing, md);

    .optionItem {
      width: auto;
    }
  }

  &_notification {
    margin: map-get($spacing, md) 0 0;
  }
}
