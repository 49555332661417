@import "../../../assets/scss/avocadoVariables";
@import "../../../assets/scss/mixin";

.sharedWeeks {
  display: flex;
  flex-direction: column;

  &_title {
    margin: 0 0 map-get($spacing, xxl);
  }
}
