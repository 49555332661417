@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/avocadoVariables";

.advanceTable {
  display: flex;
  flex-direction: column;
  gap: map-get($spacing, sm);
}

.cardState {
  margin: map-get($spacing, lg) 0 0;
}

.advancedForm {
  border: 1px solid var(--color-neutral-30);
  border-radius: map-get($radius, lg);
  padding: map-get($spacing, md);

  &_row {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, sm);

    &:not(:last-child) {
      border-bottom: 1px solid color-mix(in srgb, var(--color-neutral-30) 50%, transparent);
      padding: 0 0 map-get($spacing, xl);
      margin: 0 0 map-get($spacing, xl);
    }

    .rowTitle {
      color: var(--color-neutral-70);
    }

    .rowControl {
      display: flex;
      gap: map-get($spacing, sm);

      .checkboxField {
        margin-bottom: map-get($spacing, xs);
      }
    }

    .rowFields {
      display: grid;
      grid-template-columns: repeat(2, 200px);
      gap: map-get($spacing, sm);

      &.rowFields__textFields {
        grid-template-columns: repeat(2, 100px);
      }
    }
  }

  &_footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: map-get($spacing, sm);
  }
}
