@import "../../../../../../assets/scss/mixin";
@import "../../../../../../assets/scss/avocadoVariables";

.selectors {
  padding: map-get($spacing, md);

  &_title {
    margin: 0 0 map-get($spacing, xl);
  }

  &_groupFields {
    display: flex;
    gap: map-get($spacing, md);

    .select {
      width: 318px;
    }
  }

  &_multiKey {
    margin: map-get($spacing, md) 0 0;
    width: 318px;
  }

  &_term {
    width: 318px;
    margin: map-get($spacing, md) 0 0;
  }
}
