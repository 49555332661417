@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/avocadoVariables";

.modal {
  &_header {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, xs);

    .headerTopTitle {
      color: var(--color-neutral-70);
    }
  }

  &_content {
    padding: map-get($spacing, md) 0 0;
    border-top: 1px solid var(--color-neutral-10);
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: map-get($spacing, xs);
  }
}
