@import "../../../../../../assets/scss/mixin";
@import "../../../../../../assets/scss/avocadoVariables";

.componentItem {
  .componentItem_title {
    color: var(--color-neutral-50);
    margin: map-get($spacing, xxs) 0 0;
  }

  &_options {
    display: flex;
    gap: map-get($spacing, sm);
    margin: map-get($spacing, sm) 0 0;

    .radioButton {
      width: fit-content;
    }
  }

  &_select {
    margin: map-get($spacing, sm) 0 0;
    width: 318px;
  }
}
