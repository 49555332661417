@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/avocadoVariables";

.main {
  min-height: calc(100vh - 50px);

  .contentHidden {
    display: none;
  }

  .content {
    display: grid;
    grid-template-columns: minmax(190px, 230px) 1fr auto;
    gap: map-get($spacing, md);
    position: relative;
    background: var(--color-neutral-00);
    overflow-x: hidden;
    max-width: 100vw;
    padding: map-get($spacing, xxl) map-get($spacing, xxxl);

    @for $i from 100 through 200 {
      &.content__height#{$i} {
        $height: $i * 1px;
        min-height: calc(100vh - #{$height} - 50px);
      }
    }

    .aside {
      width: 100%;
    }

    .formEdit {
      min-width: calc(100% - 240px);

      &_content {
        display: flex;
        flex-direction: column;

        .infoMessage {
          width: 100%;
          margin: 0 0 map-get($spacing, md);
        }
      }

      .initCard {
        width: 100%;
      }
      .fields {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: map-get($spacing, md);

        &_content {
          flex: 3;
          display: block;
          min-width: 550px;
          max-width: calc((100 / 12) * 7%);
        }
      }
      .recommendationsContainer {
        flex: 1;
        max-width: calc((100 / 12) * 4.7%);
      }
    }
    .footer {
      padding: 2rem 0;
      gap: 1rem;
    }
  }
}
