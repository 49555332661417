@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/avocadoVariables";

.itemInfoRow {
  display: flex;
  flex-wrap: wrap;
  gap: map-get($spacing, xl);

  &.itemInfoRow__details {
    margin: map-get($spacing, xl) 0 0;
    gap: map-get($spacing, xl);
  }

  &_column {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: map-get($spacing, xs);

    .columnIcon {
      flex-shrink: 0;
    }

    .columnText {
      display: flex;
      align-items: flex-start;
      gap: map-get($spacing, xxs);

      &.columnText__vertical {
        flex-direction: column;
        align-items: flex-start;
      }

      &_link,
      a[class*="_link"] {
        color: var(--color-primary-50);
        font-weight: map-get($fonts, medium);
      }

      &_link,
      &_text {
        &.columnText_link__truncated,
        &.columnText_text__truncated {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
