@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/mixin";

[class*="linkErrors_link"] {
  color: var(--color-neutral-90);
  font-weight: map-get($fonts, bold);
}

.clientCodeManagement {
  display: flex;
  flex-direction: column;
  padding: map-get($spacing, xxl) map-get($spacing, xxxl);

  .clientCodeManagement_title {
    color: var(--color-neutral-85);
    margin: 0 0 map-get($spacing, sm);
  }

  &_informationBox {
    background: var(--color-blocked-opacity);
    border-radius: map-get($radius, lg);
    margin: 0 0 map-get($spacing, lg);
    min-width: 471px;
    padding: map-get($spacing, xs);
    width: fit-content;

    .title {
      color: var(--color-neutral-85);
      margin: 0 0 map-get($spacing, xs);
    }

    .subtitle {
      color: var(--color-neutral-85);
      font-weight: map-get($fonts, bold);
      margin: 0 0 map-get($spacing, xs);
    }

    .selectedLeague {
      color: var(--color-neutral-70);
      margin: 0 0 map-get($spacing, sm);
    }

    .content {
      display: flex;
      gap: map-get($spacing, xxs);

      &_text {
        color: var(--color-neutral-85);
      }

      &_bold {
        color: var(--color-neutral-70);
      }

      &_divider {
        height: map-get($spacing, md);
      }
    }
  }

  &_control {
    .tooltipLabel {
      display: flex;
      flex-direction: column;
      gap: map-get($spacing, xxs);
      list-style: disc;
      padding-left: map-get($spacing, lg);
    }

    .label {
      color: var(--color-neutral-90);
      display: block;
      font-size: map-get($sizeText, 12);
      font-weight: map-get($fonts, bold);
      margin: 0 0 map-get($spacing, xs);
    }

    .textField {
      [class*="_textField_content"] {
        max-width: 100px;
      }
    }

    .errorCard {
      margin: map-get($spacing, sm) 0 0;
      max-width: 551px;
    }
  }

  &_actions {
    display: flex;
    gap: map-get($spacing, sm);
    margin: map-get($spacing, xxl) 0 0;
  }
}
