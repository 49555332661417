@import "../../../../../assets/scss/avocadoVariables";

.simpleResourceList {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: map-get($spacing, xxs);
  overflow: hidden;

  &_item {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 28px;
    gap: map-get($spacing, xs);
    border-radius: map-get($radius, md);
    background-color: var(--color-blocked-opacity);
    width: 100%;
    padding: 0 map-get($spacing, xs);
    min-height: 28px;

    .itemContent {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: map-get($spacing, xs);
      padding: map-get($spacing, xxs);

      &_text {
        color: var(--color-neutral-70);
      }
    }
  }
}
