@import "../../../../../../assets/scss/mixin";
@import "../../../../../../assets/scss/avocadoVariables";

.instructor {
  padding: map-get($spacing, md) 0 0;

  &_checkbox {
    margin: 0 0 map-get($spacing, md);
  }

  &_fields {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 128px;
    gap: map-get($spacing, md);
    margin: 0 0 map-get($spacing, sm);

    &__noSpacing {
      margin: 0;
    }

    div[class*="reactSelect__input-container"] {
      color: var(--color-neutral-90);
    }

    .fieldLink {
      display: flex;
      margin: 0 0 0 auto;
    }
  }

  & div[class*="reactSelect__option--is-disabled"] {
    background-color: var(--color-neutral-00) !important;
  }
}
