@import "./../../../assets/scss/mixin";
@import "./../../../assets/scss/avocadoVariables";

.infoPanel {
  padding: map-get($spacing, xxl) map-get($spacing, xs) map-get($spacing, lg)
    map-get($spacing, xxxl);

  [class*="select_label"] {
    display: none;
  }

  &_weekly {
    margin: map-get($spacing, lg) -0.5rem 0;
  }
}
