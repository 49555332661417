@import "../../../../../assets/scss/avocadoVariables";

$link-colors: (
  "color_palette_2": var(--color-palette-2-50),
  "color_palette_1": var(--color-palette-1-50),
  "color_palette_3": var(--color-palette-3-50),
  "color_palette_4": var(--color-palette-4-50),
  "color_palette_5": var(--color-palette-5-50),
  "color_palette_6": var(--color-palette-6-50),
  "color_palette_7": var(--color-palette-7-50),
  "color_palette_8": var(--color-palette-8-50),
  "color_palette_9": var(--color-palette-9-50),
  "color_palette_10": var(--color-palette-10-50),
  "color_palette_11": var(--color-palette-11-50),
  "color_palette_12": var(--color-palette-12-50),
  "color_palette_13": var(--color-palette-13-50),
  "color_palette_14": var(--color-palette-14-50),
  "color_palette_15": var(--color-palette-15-50),
  "color_palette_16": var(--color-palette-16-50),
  "color_palette_17": var(--color-palette-17-50),
);

.linkCard {
  width: 100%;
  max-width: unset;

  &:not(:last-of-type) {
    margin: 0 0 map-get($spacing, md);
  }

  &_customHeader {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .linkCode {
      margin: 0 map-get($spacing, xxs) 0 0;
    }

    .codeManagement {
      [class*="_link_content"] {
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          margin: 0 map-get($spacing, xxs) 0 0;
        }
      }
    }

    .codeManagement,
    .scheduleEdit,
    .packageEdit {
      margin: 0 map-get($spacing, xs) 0 0;
    }
  }

  &_circle {
    width: 16px;
    height: 16px;
    border-radius: 100%;
  }

  &_content {
    display: grid;
    gap: map-get($spacing, sm);
    grid-template-columns: 1fr auto;
  }

  [class*="_accordion_head"] {
    [class*="_heading"] {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: map-get($spacing, xs);
    }
  }

  @each $name, $color in $link-colors {
    &__#{$name} {
      border-color: $color !important;

      .linkCard_circle {
        background-color: $color;
      }
    }
  }
}
