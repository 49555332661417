@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/mixin";

.linkChangeHistory {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: map-get($spacing, lg);

  &_header {
    width: 100%;
    max-width: 1120px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: map-get($spacing, sm);
    margin: 0 0 map-get($spacing, lg);

    .headerTitle {
      font-weight: 400;
      color: map-get($colors, layout-grey-90);
    }

    .headerSwitch {
      &__active,
      &__active:hover {
        > [class*="_input"] {
          background-color: map-get($colors, darwined-blue-10);
        }
      }

      > [class*="_label"] {
        font-size: map-get($sizeText, 14);
        color: map-get($colors, layout-grey-90);
      }
    }
  }

  &_banner {
    width: 100%;
    padding: map-get($spacing, md);
    background-color: map-get($colors, foris-white);
    box-shadow: map-get($shadow, sm);
    border-radius: map-get($radius, md);
    max-width: 1120px;
    margin: 0 0 map-get($spacing, lg);

    .bannerDownload {
      padding: 0;
      min-height: unset;
      min-width: unset;
      color: map-get($colors, darwined-blue-50);
      fill: map-get($colors, darwined-blue-50);

      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
      }
    }
  }
}
