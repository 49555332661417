@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/avocadoVariables";

.ctnClipBoard {
  align-items: center;
  display: flex;
  gap: map-get($spacing, xxs);

  &_btn {
    background: none;
    cursor: pointer;
    height: 28px;
    width: 28px;

    &:hover,
    &:active,
    &:focus {
      svg > g > path {
        fill: var(--color-neutral-05);
      }
    }
  }
}
