@import "../../../../../assets/scss/avocadoVariables";

.cardSession {
  display: flex;
  flex-direction: column;
  background-color: var(--color-neutral-00);
  border-radius: map-get($radius, lg);
  box-shadow: var(--shadow-sm);
  padding: map-get($spacing, md);
  max-width: 424px;
  width: 100%;

  &_title {
    margin: 0 0 map-get($spacing, xl);
  }

  &_details {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: map-get($spacing, xxl);

    &:not(:last-child) {
      margin: 0 0 map-get($spacing, md);
    }

    .detailsItem {
      display: flex;
      flex-direction: column;
      gap: map-get($spacing, xxs);

      &_title {
        color: var(--color-neutral-70);
      }

      &_updates {
        display: flex;
        flex-direction: column;
        gap: map-get($spacing, xxs);

        &.detailsItem_updates__grouped {
          flex-direction: row;
          flex-wrap: wrap;
          gap: map-get($spacing, xs);
        }
      }

      &_text {
        display: flex;
        gap: map-get($spacing, xs);

        &.detailsItem_text__deleted {
          text-decoration-line: line-through;
          color: var(--color-neutral-50);
        }
      }
    }
  }
}
