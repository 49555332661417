@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/avocadoVariables";

.restActions {
  display: grid;
  gap: map-get($spacing, md);
  grid-template-columns: 1fr 1fr;
  margin: map-get($spacing, md) 0 0;

  &_box {
    display: flex;
    padding: map-get($spacing, xl) map-get($spacing, md);

    .content {
      flex: 1;
      margin: 0 0 0 map-get($spacing, sm);

      &_title {
        color: var(--color-neutral-70);
        margin: 0 0 map-get($spacing, xs);
      }

      &_description {
        color: var(--color-neutral-70);
      }

      &_header {
        display: flex;
        justify-content: space-between;

        .title {
          color: var(--color-neutral-70);
        }
      }

      &_button {
        margin: map-get($spacing, xl) 0 0;
      }

      &_buttonGroup {
        display: flex;
        gap: map-get($spacing, md);
      }
    }
  }
}
