@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/avocadoVariables";

.ctnAdvanceSearch {
  &_boxContent {
    padding: map-get($spacing, md);
  }

  &_actionButton {
    margin: map-get($spacing, xxl) 0 0;
  }
}
