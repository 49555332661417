@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/avocadoVariables";

.header {
  [class*="header_infoItem_container"] {
    margin: map-get($spacing, xs) 0 0;
  }
}

.home {
  padding: map-get($spacing, xxl) map-get($spacing, xxxl);
}
