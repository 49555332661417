@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/avocadoVariables";

.blockRange {
  padding: map-get($spacing, md) 0 0;

  &_radioOptions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: map-get($spacing, xl);
    margin: 0 0 map-get($spacing, md);
    padding-bottom: map-get($spacing, xs);
    border-bottom: 1px solid var(--color-neutral-30) !important;

    .radioButton {
      width: auto;
    }
  }

  &_checkbox {
    margin: 0 0 map-get($spacing, md);
  }

  &_timeOptions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: map-get($spacing, xs);

    &:not(:last-child) {
      margin: 0 0 map-get($spacing, md);
    }

    .divider {
      flex-shrink: 0;
      width: 8px;
      height: 1px;
      background-color: var(--color-neutral-30);
      margin: map-get($spacing, lg) 0 0;
    }

    .timeOption {
      max-width: 92px;
    }
  }

  &_bottomBorder {
    padding-bottom: map-get($spacing, md);
    border-bottom: 1px solid var(--color-neutral-30) !important;
  }

  &_dashIcon {
    color: map-get($colors, layout-grey-30) !important;
    align-self: flex-end;
    padding-bottom: 0.8rem;
    padding-left: 1.5rem;
  }
  &__days_label {
    @include text(0.875rem, layout-grey-90, regular);
    margin-bottom: 10px;
  }

  .options {
    margin: 0 0 map-get($spacing, xs);
    display: flex;
    flex-wrap: nowrap;

    &:last-child {
      margin: 0;
    }
    &_item {
      margin: 0 0 0 1.5rem;
      &:first-of-type {
        margin: 0;
      }
      &.options_item__checkbox {
        input {
          border: 1px solid map-get($colors, layout-grey-30);
        }
        width: auto;
      }
      &_primaryCheckbox {
        width: auto;
      }
    }
  }
  &_input {
    width: 80px;

    &:not(:last-child) {
      margin: 0 0 map-get($spacing, md);
    }
  }
  .errorText {
    @include text(0.75rem, layout-grey-90);
  }
}
