@import "../../../../assets/scss/avocadoVariables";
@import "../../../../assets/scss/mixin";

.advancedLabelsSelectors {
  display: flex;
  flex-direction: column;

  .advancedLabelsSelectors_title {
    color: var(--color-neutral-70);
    margin: 0 0 map-get($spacing, md);
  }

  &_divider {
    height: map-get($borders, md);
    margin: map-get($spacing, md) 0 map-get($spacing, lg);
    width: 100%;
  }

  &_header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: map-get($spacing, sm);

    .headerTitle {
      flex-shrink: 0;
      font-size: map-get($sizeText, 14);
      font-weight: map-get($fonts, medium);
      color: map-get($colors, layout-grey-70);
    }

    .headerLine {
      width: 100%;
      height: 1px;
      background-color: map-get($colors, layout-grey-10);
    }
  }

  &_control {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, xs);
    width: 100%;
    max-width: 280px;
    margin: 0 0 map-get($spacing, sm);

    .labelsSelector {
      width: 100%;
    }
  }

  &_labels {
    display: flex;
    flex-wrap: wrap;
    gap: map-get($spacing, xs);
  }
}
