@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/avocadoVariables";

.container {
  &:not(:last-of-type) {
    margin: 0 0 map-get($spacing, sm);
  }

  &_informationBox {
    background: var(--color-blocked-opacity);
    border-radius: map-get($radius, lg);
    margin: map-get($spacing, xs) 0 0;
    padding: map-get($spacing, xs);
  }

  .container_informationBox .cntOptions .detailOption_item {
    color: var(--color-neutral-90);
  }

  [class*="reactSelect__option"] {
    /* Apply styles to element when it is active */
    &:active {
      p {
        color: var(--color-gray-00);
      }
    }

    /* Apply styles to title */
    [class*="selectors_cntOptions_title"] {
      color: var(--color-neutral-70);
      font-size: 0.75rem;
      margin: 0 0 map-get($spacing, xxs);
    }
  }

  /* Item selected */
  [class*="reactSelect__option--is-selected"] {
    p {
      color: var(--color-gray-00);
    }

    [class*="selectors_cntOptions_title"] {
      color: var(--color-gray-00);
      font-size: 0.75rem;
      margin: 0 0 map-get($spacing, xxs);
    }
  }
}

.cntOptions {
  &_title {
    @include text(0.875rem, _, regular);
    color: var(--color-neutral-90);
    white-space: normal;

    .bold {
      font-weight: map-get($fonts, medium);
    }
  }

  .detailOption {
    &:not(:last-child) {
      margin: 0 0 map-get($spacing, xxs);
    }

    &_item {
      color: var(--color-neutral-50);
      font-size: map-get($sizeText, 12);
      text-transform: uppercase;
      white-space: normal;

      &:last-of-type {
        .bold {
          &::after {
            content: "";
            padding: 0;
          }
        }
      }
      .bold {
        font-weight: map-get($fonts, medium);
        padding: 0 0 0 map-get($spacing, xxs);
        &::after {
          content: "|";
          color: var(--color-neutral-30);
          padding: 0 map-get($spacing, xxs);
        }
      }
    }
  }
}
