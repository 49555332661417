@import "../../../assets/scss/avocadoVariables";
@import "../../../assets/scss/mixin";

.list_validation {
  list-style: none;
  padding: map-get($spacing, xxl) 0 0;

  &_item {
    color: var(--color-warning-50);
    font-size: map-get($sizeText, 14);
    padding: map-get($spacing, xxs) 0 0;
    &:first-of-type {
      padding: 0;
    }
    &.list_validation_item__error {
      color: var(--color-error-50);
      font-size: map-get($sizeText, 14);
    }
  }

  &_checkbox {
    margin: map-get($spacing, md) 0 0;
  }
}
