@import "../../../../assets/scss/avocadoVariables";

.groupsTable {
  &_title {
    margin: 0 0 map-get($spacing, sm);
  }

  .groupVacanciesInput {
    max-width: 60px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: map-get($spacing, xl);
  }
}
