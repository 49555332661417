@import "../../../assets/scss/mixin";
@import "../../../assets/scss/avocadoVariables";

.sharedCheckbox {
  width: 100%;
  max-width: 720px;
  padding: map-get($spacing, md) 0;

  &.sharedCheckbox__noPadding {
    padding: 0;
  }

  .sharedCheckbox_title {
    margin: 0 0 map-get($spacing, md);
    color: var(--color-neutral-70);
  }

  &_field {
    padding: 0;
  }

  &_assignments {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, sm);
  }

  &_days {
    display: flex;
    flex-wrap: wrap;
    gap: map-get($spacing, xl);
    justify-content: flex-start;

    .checkboxDay {
      width: auto;
    }
  }

  .sharedCheckbox_error {
    margin: map-get($spacing, sm) 0 0;
    color: var(--color-error-50);
  }
}
