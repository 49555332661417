@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/avocadoVariables";

.formItem {
  padding: map-get($spacing, md);
  border: 1px solid var(--color-neutral-30);
  border-radius: map-get($radius, lg);
  background-color: var(--color-neutral-00);

  &:not(:last-of-type) {
    margin: 0 0 map-get($spacing, sm);
  }

  &_warning {
    margin: map-get($spacing, md) 0 0;
  }

  .header {
    flex-wrap: nowrap;

    &_title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: map-get($spacing, xxs);
    }

    &_edit {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: map-get($spacing, xxs);

      .editLabel {
        color: var(--color-neutral-70);
      }
    }

    &_permissionsMessage {
      color: var(--color-neutral-50);
      display: flex;
      justify-content: flex-end;
      gap: map-get($spacing, xxs);
    }
  }
}

.btnTrash {
  @include flex(center, center);
  width: 40px;
  background: transparent;
  color: map-get($colors, darwined-blue-30);
  cursor: pointer;
  border: none;
  outline: none;
  &:hover {
    color: map-get($colors, darwined-blue-50);
  }
  &:active {
    color: map-get($colors, darwined-blue-70);
  }
  &__disabled {
    cursor: default;
    color: map-get($colors, layout-grey-30);
    &:active,
    &:hover {
      color: map-get($colors, layout-grey-30);
    }
  }
}
