@import "../../../../assets/scss/avocadoVariables";
@import "../../../../assets/scss/mixin";

.timeRange {
  display: flex;
  flex-direction: column;
  margin: map-get($spacing, md) 0 0;

  &_fields {
    display: grid;
    gap: map-get($spacing, xs);
    grid-template-columns: 92px 8px 92px;

    .fieldSeparator {
      align-items: center;
      color: var(--color-neutral-90);
      display: flex;
      justify-content: center;
      margin: map-get($spacing, lg) 0 0;
    }

    .fieldControl {
      &_label {
        margin: 0 0 map-get($spacing, xs);
        font-weight: map-get($fonts, bold);
      }

      &_input {
        background: var(--color-neutral-00);
        border-radius: map-get($radius, md);
        border-radius: map-get($radius, md);
        border: map-get($borders, md) solid var(--color-neutral-30);
        font-size: map-get($sizeText, 14);
        min-height: 36px;
        padding: map-get($spacing, xs) map-get($spacing, sm);
        width: 100%;
        color: var(--color-neutral-90);

        &:hover {
          border: map-get($borders, md) solid var(--color-neutral-50);
        }

        &:active {
          border: map-get($borders, md) solid var(--color-neutral-50);
        }

        &:focus {
          border: map-get($borders, md) solid var(--color-neutral-50);
        }

        &::placeholder {
          color: var(--color-neutral-50);
        }
      }
    }
  }

  .timeRange_error {
    margin: map-get($spacing, sm) 0 0;
    color: var(--color-error-50);
  }
}
