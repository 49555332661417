@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/avocadoVariables";

.ctnAdvanceSearch {
  &_boxContent {
    padding: map-get($spacing, md);

    .selectorContainer {
      display: flex;
      flex-direction: column;
      gap: map-get($spacing, sm);
    }

    .divider {
      height: map-get($borders, md);
      margin: map-get($spacing, md) 0 map-get($spacing, lg);
      width: 100%;
    }

    .typeContract {
      width: 264px;
    }
  }

  &_actionButton {
    margin: map-get($spacing, xxl) 0 0;
  }
}
