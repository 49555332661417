@import "../../../../../assets/scss/avocadoVariables";

.linkComponentsTable {
  display: flex;
  flex-direction: column;

  &_title {
    margin: 0 0 map-get($spacing, sm);
  }

  .sharedLinkPill {
    justify-self: flex-start;
  }

  div[class*="_tableDesktop"] {
    height: 100%;
  }

  [class*="_bodyRow"] {
    &:not(:first-child) {
      [class*="_bodyRow_column"] {
        border: none;
      }
    }
  }
}
