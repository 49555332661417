@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/avocadoVariables";

.itemInfo {
  &_content {
    padding: map-get($spacing, md);
    border-radius: map-get($radius, lg);
    background-color: var(--color-blocked-opacity);
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, sm);

    .classroomRow {
      flex-wrap: nowrap;
    }

    .classroomGroup {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: map-get($spacing, xs);

      div[class*="itemInfoRow"] {
        &:not(:first-of-type) {
          svg {
            opacity: 0;
          }
        }
      }
    }

    .contentGroup {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: map-get($spacing, xxs);
      max-height: 97px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: map-get($radius, lg);
      }

      &::-webkit-scrollbar-thumb {
        background: var(--color-neutral-30);
        border-radius: map-get($radius, lg);
      }

      &::-webkit-scrollbar-corner {
        background: transparent;
      }
    }

    .contentRow__details {
      margin: map-get($spacing, xl) 0 0;
      gap: map-get($spacing, xl);
    }

    .absent_resource {
      &__required {
        color: var(--color-error-50);
      }
      &__not_required {
        color: var(--color-neutral-50);
      }
    }
  }

  .errorCard {
    margin: map-get($spacing, md) 0 0;

    .errorList {
      list-style: initial;
      display: flex;
      flex-direction: column;
      gap: map-get($spacing, xxs);

      &_item {
        color: var(--color-neutral-70);
      }
    }
  }

  &_linkSelect {
    margin: map-get($spacing, md) 0 0;
  }

  &_footer {
    padding: map-get($spacing, xxl) 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: map-get($spacing, sm);
  }
}
