@import "../../../../assets/scss/avocadoVariables";

.packageLinkManagement {
  width: 100%;
  margin: 0 auto;
  padding: map-get($spacing, xxl) map-get($spacing, xxxl);
  display: flex;
  flex-direction: column;

  &_title,
  &_linkSelector {
    margin: 0 0 map-get($spacing, lg);
  }

  &_linkSelector {
    max-width: 300px;
  }

  .infoCard {
    margin: 0 auto map-get($spacing, lg) 0;
    width: auto;
  }

  &_table {
    .vacanciesHead,
    .vacanciesBody {
      [class*="_columnContent"] {
        justify-content: flex-end;
        text-align: right;
      }
    }

    .cellWithIcon {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: map-get($spacing, xxs);

      .icon {
        flex-shrink: 0;
      }

      .trashIcon {
        color: map-get($colors, layout-grey-50);
      }

      .addIcon {
        color: map-get($colors, darwined-blue-30);
      }
    }
  }

  .errorCard {
    margin: map-get($spacing, sm) auto 0 0;
    width: auto;
  }

  &_actions {
    margin: map-get($spacing, lg) 0 0;
    padding: map-get($spacing, xxs) 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: map-get($spacing, sm);
  }

  .packageLink {
    color: var(--color-neutral-90);
    font-weight: map-get($fonts, bold);

    &:hover {
      text-decoration: underline;
    }
  }
}
