@import "../../../../../assets/scss/avocadoVariables";

.savedSectionsList {
  &_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: map-get($spacing, sm);
  }
}
