@import "../../../assets/scss/mixin";
@import "../../../assets/scss/avocadoVariables";

.cnt_range {
  .range_content {
    height: 36px;
    display: flex;
    width: 100%;
    position: relative;
    &.range_content__disabled {
      .range_content_pointer {
        background: var(--color-neutral-30);
        border-color: var(--color-neutral-30);
        border-radius: 50%;
        .detail_range {
          background: var(--color-neutral-00);
          border-radius: map-get($radius, md);
          border: map-get($borders, md) solid var(--color-neutral-30);
          color: var(--color-neutral-90);
          font-size: map-get($sizeText, 10);
          font-weight: map-get($fonts, bold);
          &::before,
          &::after {
            border-top-color: var(--color-neutral-10);
          }
          &::before {
            border-top-color: var(--color-neutral-30);
          }
        }
      }
    }
    &_init,
    &_end {
      font-size: map-get($sizeText, 12);
      color: var(--color-neutral-90);
      position: absolute;
      bottom: -10px;
    }

    &_init {
      left: 0;
    }

    &_end {
      right: 0;
    }

    &_line {
      height: 3px;
      width: 100%;
      background: map-get($colors, layout-grey-10);
      border-radius: 1px;
      align-self: center;
    }

    &_pointer {
      background: var(--color-primary-50);
      border-radius: 50%;
      border: map-get($borders, md) solid var(--color-primary-60);
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: var(--shadow-md);
      outline: none;
      z-index: map-get($zIndex, basic) !important;
      .detail_range {
        background: var(--color-neutral-00);
        border-radius: map-get($radius, md);
        border: map-get($borders, md) solid var(--color-neutral-30);
        color: var(--color-neutral-90);
        font-size: map-get($sizeText, 10);
        font-weight: map-get($fonts, bold);
        position: absolute;
        bottom: 30px;
        margin: 0;
        padding: 0.365rem 0;
        width: 70px;
        text-align: center;
        &::before,
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0;
          height: 0;
          border: 8px solid transparent;
          border-top-color: var(--color-neutral-10);
          border-bottom: 0;
          margin-left: -8px;
          margin-bottom: -7px;
          z-index: map-get($zIndex, element);
          display: block;
        }
        &::before {
          bottom: -2px;
          z-index: map-get($zIndex, basic);
          border-top-color: var(--color-neutral-30);
        }
      }
    }
  }
}
