@import "../../assets/scss/avocadoVariables";
@import "../../assets/scss/mixin";

.cntAdvanceSearch {
  background: var(--color-neutral-00);
  padding: map-get($spacing, xxl) map-get($spacing, xxxl);

  &_title {
    align-items: center;
    display: flex;
    gap: map-get($spacing, sm);
    margin: 0 0 map-get($spacing, xl);
  }

  &_select {
    width: 264px;
    margin: 0 0 map-get($spacing, sm);
  }
}
