@import "../../../../../assets/scss/avocadoVariables";

.assignationHeader {
  align-items: center;
  background-color: var(--color-neutral-00);
  box-shadow: var(--shadow-elevate);
  display: flex;
  padding: map-get($spacing, xs) map-get($spacing, xxxl);
  min-height: 52px;
  justify-content: space-between;
  position: relative;
  z-index: 5;

  &_leftContent,
  &_rightContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: map-get($spacing, md);
  }

  &_leftContent {
    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: map-get($spacing, sm);
    }
  }
}
