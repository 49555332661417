@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/avocadoVariables";

.linkSelector {
  [class*="reactSelect__option"] {
    &:hover {
      background-color: var(--color-gray-30-20) !important;
    }

    &:active,
    &:focus {
      background-color: var(--color-primary-50) !important;
    }
  }

  &.linkSelector__dark {
    [class*="reactSelect__option"] {
      &:hover {
        background-color: var(--color-gray-100-10) !important;
      }
    }
  }

  .linkLabelWrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: map-get($spacing, xs);
    width: calc(100% + 20px);

    &.linkLabelWrapper__selected,
    &:active,
    &:focus {
      background-color: var(--color-primary-50);
      padding: map-get($spacing, xs) map-get($spacing, sm);
      margin: -8px -12px;

      .linkOptionLabel {
        color: var(--color-gray-00);

        &_secondaryLabel {
          color: var(--color-gray-00) !important;
        }
      }

      svg {
        path {
          fill: var(--color-gray-00) !important;
        }
      }
    }

    &_icon {
      color: map-get($colors, layout-grey-50);
      flex-shrink: 0;
    }

    .linkOptionLabel {
      font-size: map-get($sizeText, 12);
      line-height: map-get($sizeText, 16);
      color: var(--color-neutral-70);
      font-weight: map-get($fonts, regular);
      display: flex;
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
      white-space: normal;
      width: 100%;

      b {
        font-weight: map-get($fonts, medium);
      }

      &.linkOptionLabel__groups {
        gap: map-get($spacing, xxs);

        .linkOptionLabel_secondaryLabel {
          font-size: map-get($sizeText, 10);
          line-height: map-get($sizeText, 12);
          color: var(--color-neutral-50);
        }
      }
    }
  }
}
